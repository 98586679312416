import { useSearchParams } from 'react-router-dom';
import './css/template.css';
import { errorCodeMessages } from './ts/errorCodes';

interface IErrorTemplateProps {
    additionalErrorMessage?: string,
    errorCode: number
}


function ErrorTemplate(props: IErrorTemplateProps) {

    const [searchParams, setSearchParams] = useSearchParams();

    const getTitle = () => {

        var message = errorCodeMessages[props.errorCode];

        if (message === undefined) {
            message = "Unknown Error."
        }

        return (
            <h1>{message}</h1>
        )
    }

    const getRefCode = (): JSX.Element | undefined => {

        const ref: string | null = searchParams.get("ref");

        if (ref !== undefined && ref !== null) {
            return (
                <p id='ref-string'>Ref: {ref}</p>
            )
        }
    }

    const getDetails = (): JSX.Element | undefined => {

        var details: any = searchParams.get("details");

        if (details !== undefined && details !== null) {
            return (
                <p id='details'>Details: {details}</p>
            )
        }
    }

    return (
        <div id="wrap">
			<img
				className="not-found-icon"
				src="https://cdn.ridelinks.com/error-pages/not-found-icon.png"
				alt="Not Found Image"
			/>
			<img
				className="ridelinks-logo"
				src="https://cdn.ridelinks.com/logos/rideLinks-logo-lg.png"
				alt="RideLinks Inc. Logo"
			/>

			{getTitle()}
			<p>Error {props.errorCode}</p>
            {getRefCode()}
            {getDetails()}
            
		</div>
    )
}

export default ErrorTemplate;
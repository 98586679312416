// Library imports 
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
// Components
import ErrorTemplate from "./errorTemplate";

function App() {

	return (
		<div className="App">
			<Router>
				<Routes>
					<Route path="/400" element={<ErrorTemplate errorCode={400}/>} />
					<Route path='/403' element={<ErrorTemplate errorCode={403}/>} />
					<Route path='/*' element={<ErrorTemplate errorCode={404}/>} />
				</Routes>
			</Router>
		</div>
	);
}

export default App;
